.rightside-part{
    /* background: linear-gradient(to bottom, #87CEEB 0%, #fff 50%, #B0E0E6 90%); */
    /* background-color: #cef0ff; */
   
    background-image: linear-gradient(to bottom left, #d3f2fe 0%, #fefeff 60%, #8eddfd 100%);
    
}

.bottomside-part{
  background-color: #d0edf0;

  /* opacity: 0.6; */
}

.text-title {
  color: #1b2559;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.hightlight-text {
  color: #1b2559;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.logo-container {
  display: flex;
  justify-content: center;
}
.login-card {
  border-radius: 44px;
  border: 1px solid #e2e8f0;
  background: #fff;
  padding: 1.8rem;
  box-shadow: 16px 24px 40px 4px rgba(112, 144, 176, 0.08);
  margin: 1.4rem;
}
.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.primary-heading {
  color: #1b2559;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin:1rem
}
.secondary-heading{
    color: #718096;
    font-size: 18px;
}
.mobile-field {
  border-radius: 45px;
  border: 1px solid #e2e8f0;
  padding: 12px 18px;
  /* width: 300px; */
}
.mobile-type {
  border-radius: 45px;
  border: 1px solid #e2e8f0;
  padding: 12px 18px;
  /* width: 60px; */
}
.mobile-type:active{
    border: none;
}
.mobile-type-otp {
    border-radius: 50%;
    border: 1px solid #ced7e3;
    padding: 12px ;
    width: 50px;
    height: 50px;
    color: black;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    appearance: textfield;
}
.send-btn {
  border-radius: 45px;
  background: #F9FAFB;
  color: rgba(0, 0, 0, 0.30);
  
  border: none;
  padding: 16px 30px;
  cursor: pointer;
}
/* .send-btn:hover{
  background: linear-gradient(270deg, #5be0ff 0%, #0062e9 100%);
  color: #ffffff;
} */
.timer-btn{
  border-radius: 45px;
  background: #F9FAFB;
  color: rgba(0, 0, 0, 0.30);
  
  border: none;
  padding: 16px 30px;
}

.signUp-btn {
  /* border-radius: 45px;
  background: #e8f8ff;
  color: #ffffff;
  border: none;
  padding: 16px 30px;
  cursor: pointer; */
  border-radius: 45px;
  background: linear-gradient(270deg, #5be0ff 0%, #0062e9 100%);
  color: #ffffff;
  
  border: none;
  padding: 16px 30px;
  cursor: pointer;

}
.disabled :hover{
  background: linear-gradient(270deg, #5be0ff 0%, #0062e9 100%);
  color: #ffffff;;
}
.signUp-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  background: var(--text, linear-gradient(270deg, #5be0ff 0%, #0062e9 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



.btn-container{
    margin-top: 1.6rem;
    justify-content: center;
}


.form-design{
  margin-top: 120px;
}
.login-pageHight{
  height: 100vh;
}

@media screen and (min-width: 430px) and (max-width: 2560px) {
  .mob-screen{
    display: none !important;
  }
}
@media (max-width: 425px){
  .rightside-part{
    display: none;
   
  }
  .form-design{
    margin-top: 10px;
  }
  .login-pageHight{
    height: auto;
  }
}
/* rightside-part */
