.search-result {
  padding: 14px;

}
.ant-input {
  font-weight: 600  !important;
}
.edit-icon {
  font-size: 24px;
}
.user-icon {
  font-size: 22px;
  color: blue;
  border-radius: 50%;
  border: 1px solid #f0f3f7;
  padding: 12px;
  margin-top: 2px;
}
.card {
  box-shadow: 14px 27px 45px 4px rgba(112, 144, 176, 0.2);
  border: none;
  border-radius: 14px;
  background: #fff;
  margin-bottom: 2.4rem;
}

.para {
  color: #1b2559;
  /* font-family: Plus Jakarta Sans; */
  font-size: 17px;
  line-height: 24px;
  
}
li {
  color: #1b2559;
  font-family: Plus Jakarta Sans;
  font-size: 17px;
  margin-bottom: 5px;
}
.regerate-res{
  background: linear-gradient(270deg, rgba(21, 88, 182, 1) 0%, rgba(18, 56, 109, 1) 100%);
  border: none;
  padding: 14px 20px;
  font-weight: bold;
  color: #fff;
  border-radius: 15px;
}

.consult-btn{
  background: linear-gradient(91deg, #5F5F5F 1.62%, #202020 106.36%);
  border: none;
  padding: 16px 36px;
  font-weight: bold;
  font-size: 1rem;
  color: #fff;
  border-radius: 15px;
   cursor: pointer;
}

.green-icon {
  color: rgb(54, 226, 54); /* Set the desired color for the icon */
}
.custom-large-search .ant-input {
  /* Set your desired width and height here */ /* Adjust to your preferred width */
  height: 39px; /* Adjust to your preferred height */
  font-size: 20px; /* Adjust to your preferred font size */
}

.ant-input-search-button{
  /* color: black !important; */
  height: 51px !important;
  width: 51px !important;
  color: black !important;
  background-color: #fafafa !important;
  box-shadow: none;
  border: 1px solid #f0f0f0;
}


.ant-input-search .ant-input-search-button:hover{
  background: linear-gradient(270deg, #5be0ff 0%, #0062e9 100%);
  color: #ffffff;
}



.ant-input-search-button:hover .anticon {

  color: #fff;
}
.ant-input-clear-icon{
  display: none;
}