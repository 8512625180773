/* MyTable.css */
/* .ant-table-cell {
  font-size: 16px; 
} */
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.menu {
  font-size: 1rem;
  list-style: none;
  gap: 1.5rem;
  cursor: pointer;
  margin: 0;
  display: flex;
  gap: "14px";
}

.btn1 {
  border-radius: 45px;
  background: linear-gradient(270deg, #5be0ff 0%, #0062e9 100%);
  box-shadow: 0px 17px 12px 4px rgba(112, 144, 176, 0.08);
  color: #ffffff;
  border: none;
  padding: 12px 30px;
  cursor: pointer;
  text-decoration: none;
}
.btn2 {
  border-radius: 45px;
  color: #292929;
  border: 1px solid #f4f7fe;
  background: #fff;
  box-shadow: 0px 17px 12px 4px rgba(112, 144, 176, 0.08);
  padding: 12px 30px;
  cursor: pointer;
  text-decoration: none;
}

.btn2:hover{
  color: blue;
}

.btn3 {
  border-radius: 45px;
  color: #292929;
  border: 1px solid #f4f7fe;
  background: #f9fafb;
  padding: 18px 45px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}
.btn4 {

  color: #292929;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background: #f9fafb;
  padding: 18px 30px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}
.middle-img {
  width: 400px;
}

.btn3:hover {
  background: linear-gradient(270deg, #5be0ff 0%, #0062e9 100%);
  color: #ffffff;
}
.btn4:hover {
  background: linear-gradient(270deg, #5be0ff 0%, #0062e9 100%);
  color: #ffffff;
}

@media (max-width: 768px), (max-width: 992px) {
  .menu {
    position: absolute;
    list-style: none;
    gap: 2rem;
    font-weight: 500;
    flex-direction: column;
    right: 0;
    top: 5rem;
    /* height: calc(100vh - 9rem); */
    width: 50%;
    height: 250px;
    background: white;
    padding: 3rem;
    display: flex;
    border-radius: 10px;
    transition: all 200ms ease;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 1rem;
    z-index: 1;
  }
}

.menuIcon {
  display: none;
}

@media (max-width: 768px), (max-width: 992px) {
  .menuIcon {
    display: block;
  }
}

.profile_section a {
  margin: 10px 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 60px;
  color: #f2f2f6;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  background: #294c60;
  border-radius: 30px;
  font-weight: 600;
  width: 100%;
  max-width: 190px;
  padding: 0 15px;
}
.profile_section a:hover {
  color: #f2f2f6;
  text-decoration: none;
  background: #16bfda;
}



.link {
  color: #718096;
  font-family: Plus Jakarta Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
}

.search-item {
  padding: 14px;
  border-radius: 34px;
  /* z-index: -1; */
}

.ant-select-selector {
  width: 100%;
  height: 50px !important;
  border-radius: 35px !important;
  padding: 0 24px !important;
  align-items: center;
}
.ant-select-selection-search-input{
  padding: 0 15px !important;
  font-size: 18px !important;
  margin: 0 !important;
}

/* Add this CSS in your component's stylesheet or globally */
.ant-select-dropdown-empty {
  display: none !important;
}
.ant-select-arrow {
  display: none !important;
}

.ant-select-selection-search-input{
  height: 100% !important;
}





@media (max-width: 768px), (max-width: 992px) {
  .btn3 {
    width: 100%;
    background: linear-gradient(270deg, #5be0ff 0%, #0062e9 100%);
  }
  .middle-img {
    width: 300px;
  }
  .formulaw-icon{
      display: none;
  }
  .hide-formulaw{
    display: block;
  }
  .ant-table-content{
    width: 1000px;
  }
 
}

@media screen and (min-width: 992px) and (max-width: 2560px) {
  .hide-formulaw {
    visibility: hidden;
  }
}
/* @media screen and (min-width: 450px) and (max-width: 1440px) {
  .card-show {
    display: none;
  }
} */

/* @media (max-width: 1440px) {
 
  .hide-formulaw{
      display: none;
  }
} */

/* hide-formulaw */



@media (max-width: 425px) {
  .btn3 {
    margin-top: 1.4rem;
  }
  /* .table-empty{
    display: none;
  } */
}

.up-icon {
  font-size: 22px;
  color: #0062e9;
}
