body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




/* Navbar */

/* .navbar {
  padding-bottom: 1px;
  border-bottom: solid 1px #e8e8e8;
  background-color: white;
  box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.12),
    0 6px 16px -11px rgba(0, 0, 0, 0.08);
  position: sticky;
  top: 0;
  margin-bottom: 20px;
  z-index: 99990;
} */

.nav-header {
  background-color: white;
  border-bottom: 0;
  padding: 0 4px 0 24px;
}

.logo {
  width: 200px;
  float: left;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  text-transform: capitalize;
}

.navbar-menu {
  width: calc(100% - 200px);
  float: left;
}
.navbar-menu .ant-layout-header {
  padding: 0 20px;
}

.navbar-menu .ant-menu-horizontal {
  border-bottom: 0;
}

.navbar-menu .ant-menu-item {
  padding-bottom: 0px;
}

.navbar-menu .leftMenu {
  float: left;
}

.navbar-menu .rightMenu {
  float: right;
}

.menuButton {
  display: none;

}

.ant-drawer-body {
  padding: 0;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}
.ant-popover-inner-content{
  background-image:linear-gradient(180deg,#fff 0%,#e0f6ff 100%);
  /* width: 1400px; */
}
.ant-popover-arrow{
  display: none;
}

.username {
  display: none;
  margin-left: 8px;
}

@media (max-width: 1024px) {
  .menuButton {
    display: block;
    margin-right: 10px;
  }
  .middle-menu{
    display: none;

  }
  .last-menu{
    display: none;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -10px;
  }

  .ant-drawer-title > .brand-font {
    margin-bottom: 0;
  }

  .username {
    display: inline-block;
  }
}






/* Navbar End */




