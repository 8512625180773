.footer-links{
    text-decoration: none;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 1rem 1.8rem
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 1rem;
  }

.footer-container-for-result{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: unset;
  width: 100%;
 
}
  
  .app-icons{
    margin: 1rem 1.8rem;
    display: flex;
    gap: 20px;
  }
  
  
  /* Media Query for screens smaller than 600px */
  @media (max-width: 1024px) {
    .footer-container {
      display: none;
    }
    .footer-container-for-result{
      display: none;
    }
  }
  